import axios from "@/plugins/axios";

let url = "/api/v1/me/contracts";

export default {
    getContracts(data){
        return axios.get(url, data).then(result => result.data);
    },
    postContract(data){
        return axios.post(url, data).then(result => result.data);
    },
    postSignature(data, signature){
        var formData = new FormData(); formData.append("signature", signature);
        var headers = { "Content-type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/signature`, formData, { headers });
    }
}
