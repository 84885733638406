import axios from "@/plugins/axios";
import router from "../router";

import auth from "./auth";
import console from "./console";
import editor from "./editor";
// export { kakao } from "./kakao";
// export { naver } from "./naver";
import v1 from "./v1";

export default {
    auth,
    console,
    editor,
    v1,
    getResource(filename){
        return axios({
            url: filename,
            method: 'GET',
            responseType: 'blob'
        }).then(result => new File([result.data], filename));
    },
};
