import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "../plugins/axios";

Vue.use(VueRouter)

const routes = [

    {
        path: '/',
        name: 'Main',
        component: () => import('../pages/client/Main.vue'),
        props: true
    },
    {
        path: '/cert',
        name: 'Main',
        component: () => import('../pages/client/Cert.vue'),
        props: true
    },
    {
        path: '/common/kcp/cert/request',
        name: 'KCPCertRequest',
        component: () => import('../pages/common/kcp/KCPCertRequest.vue'),
        props: true
    },
    {
        path: '/common/kcp/cert/response',
        name: 'KCPCertResponse',
        component: () => import('../pages/common/kcp/KCPCertResponse.vue'),
        props: true
    },

    {
        path: '/console',
        name: 'Main',
        component: () => import('../pages/console/Main.vue'),
        props: true
    },
    {
        path: '/console/users',
        name: 'Users',
        component: () => import('../pages/console/users/UserList.vue'),
        props: true
    },
    {
        path: '/console/users/levels',
        name: 'Levels',
        component: () => import('../pages/console/users/levels/LevelList.vue'),
        props: true
    },
    {
        path: '/console/users/withdrawals',
        name: 'Withdrawals',
        component: () => import('../pages/console/users/withdrawals/WithdrawalList.vue'),
        props: true
    },
    {
        path: '/console/users/exchanges',
        name: 'Exchanges',
        component: () => import('../pages/console/users/exchanges/Exchanges.vue'),
        props: true
    },
    {
        path: '/console/common/popups',
        component: () => import('../pages/console/common/popups/PopupList.vue'),
        props: true
    },

    {
        path: '/console/blacklist',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/BlackList.vue'),
        props: true
    },
    {
        path: '/console/blacklist/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/BlackIpView.vue'),
        props: true
    },
    {
        path: '/console/blacklist/:_ip',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/BlackIpView.vue'),
        props: true
    },
    {
        path: '/console/whitelist',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/WhiteList.vue'),
        props: true
    },  
    {
        path: '/console/whitelist/create',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/WhiteIpView.vue'),
        props: true
    },
    {
        path: '/console/whitelist/:_ip',
        component: () => import(/* webpackChunkName: "console-app" */ '../pages/console/ip/WhiteIpView.vue'),
        props: true
    },
    {
        path: '/console/service/cashes',
        name: 'Cashes',
        component: () => import('../pages/console/service/CashList.vue'),
        props: true
    },
    {
        path: '/console/service/orders',
        name: 'Orders',
        component: () => import('../pages/console/service/OrderList.vue'),
        props: true
    },
    {
        path: '/console/service/certifications',
        name: 'Certifications',
        component: () => import('../pages/console/service/CertificationList.vue'),
        props: true
    },
    {
        path: '/console/service/closer',
        name: 'Closer',
        component: () => import('../pages/console/service/Closer.vue'),
        props: true
    },

    {
        path: '/console/calculation',
        name: 'Calculation',
        component: () => import('../pages/console/Calculation.vue'),
        props: true
    },

    {
        path: '/console/center/notifications',
        name: 'Notification',
        component: () => import('../pages/console/center/notifications/NotificationList.vue'),
        props: true
    },
    {
        path: '/console/center/faqs',
        name: 'Faqs',
        component: () => import('../pages/console/center/faqs/FaqList.vue'),
        props: true
    },
    {
        path: '/console/center/questions',
        name: 'Questions',
        component: () => import('../pages/console/center/questions/QuestionList.vue'),
        props: true
    },
    {
        path: '/console/terms',
        name: 'Terms',
        component: () => import('../pages/console/terms/TermsList.vue'),
        props: true
    },
    {
        path: '/console/partners/salerbook',
        name: 'Salerbook',
        component: () => import('../pages/console/partners/Salerbook.vue'),
        props: true
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    axios.defaults.headers.common['Authorization'] = sessionStorage.getItem("accessToken") ? `Bearer ${sessionStorage.getItem("accessToken")}` : "";

    next();
});

export default router
