import axios from "@/plugins/axios";

let url = "/api/console/users";

export default {
    getUsers(data){
        return axios.get(url, data).then(result => result.data);
    },
    getUser(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postUser(data){
        return axios.post(url, data).then(result => result.data);
    },
    putUser(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteUser(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postRecovery(data){
        return axios.post(`${url}/recovery`, data).then(result => result.data);
    },
    exchanges(data) {
        return axios.post(`${url}/exchanges`, data).then(result => result.data);
    }
}
