import axios from "@/plugins/axios";

let url = "/api/v1/me";

import bookmarks from "./bookmarks";
import carts from "./carts";
import contracts from "./contracts";
import coupons from "./coupons";
import inquires from "./inquires";
import likes from "./likes";
import orders from "./orders";
import points from "./points";
import purchases from "./purchases";
import reviews from "./reviews";

export default {
    bookmarks,
    carts,
    contracts,
    coupons,
    inquires,
    likes,
    orders,
    points,
    purchases,
    reviews,

    getProfile(){
        return axios.get(url).then(result => result.data);
    },
    putProfile(data){
        return axios.put(url, data).then(result => result.data);
    },
}
