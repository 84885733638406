import axios from "@/plugins/axios";

let url = "/api/console/shop/categories";

export default {
    getCategories(data){
        return axios.get(url, data).then(result => result.data);
    },
    getCategory(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postCategory(data){
        return axios.post(url, data).then(result => result.data);
    },
    putCategory(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteCategory(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postIcon(data){
        let headers = { "Content-Type": "multipart/form-data" };
        let formData = new FormData(); formData.append("icon", data.icon);
        return axios.post(`${url}/${data._id}/icon`, formData, { headers }).then(result => result.data);
    },
    deleteIcon(data){
        return axios.delete(`${url}/${data._id}/icon`).then(result => result.data);
    },
    postBanner(data, index){
        let headers = { "Content-Type": "multipart/form-data" };
        let formData = new FormData(); formData.append("banner", data.banners.filter(banner => banner)[index]);
        return axios.post(`${url}/${data._id}/banners/${index}`, formData, { headers }).then(result => result.data);
    },
    deleteBanner(data, index){
        return axios.delete(`${url}/${data._id}/banners/${index}`).then(result => result.data);
    }
}
