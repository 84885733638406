import axios from "@/plugins/axios";

let url = "/api/console/issues";

export default {
    getIssues(data){
        return axios.get(`${url}`, data).then(result => result.data);
    },
    getIssue(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postIssue(data){
        return axios.post(url, data).then(result => result.data);
    },
    putIssue(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteIssue(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
