import axios from "@/plugins/axios";
import categories from "./categories";

let url = "/api/console/portfolios";

export default {
    categories,

    getPortfolios(data){
        return axios.get(url, data).then(result => result.data);
    },
    getPortfolio(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postPortfolio(data){
        return axios.post(url, data).then(result => result.data);
    },
    putPortfolio(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deletePortfolio(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postThumb(_portfolio, file){
        var data = new FormData(); data.append('thumb', file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_portfolio}/thumb`, data, { headers });
    },
    postImage(_portfolio, file){
        var data = new FormData(); data.append('image', file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_portfolio}/image`, data, { headers });
    },
    postEstimateFile(_portfolio, file){
        var data = new FormData(); data.append('estimateFile', file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_portfolio}/estimateFile`, data, { headers });
    },
    postBeforeImage(_portfolio, index, file){
        var data = new FormData(); data.append('beforeImage', file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_portfolio}/beforeImage/${index}`, data, { headers });
    },
    postAfterImage(_portfolio, index, file){
        var data = new FormData(); data.append('afterImage', file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_portfolio}/afterImage/${index}`, data, { headers });
    }
}
