import axios from "@/plugins/axios";

let url = "/api/console/shop/reviews";

export default {
    getReviews(data){
        return axios.get(url, data).then(result => result.data);
    },
    getReview(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postReview(data){
        return axios.post(url, data).then(result => result.data);
    },
    putReview(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteReview(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
