import axios from "@/plugins/axios";

let url = "/api/v1/boards";

import comments from "./comments";

export default {
    comments,
    getBoards(data){
        return axios.get(url, data).then(result => result.data);
    },
    getBoard(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postBoard(data){
        return axios.post(url, data).then(result => result.data);
    },
    putBoard(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteBoard(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postThumb(data, thumb){
        var formData = new FormData(); formData.append("thumb", thumb);
        var headers = { "Content-Type": "multipart/form-data" };
        return axios.post(`${url}/${data._id}/thumb`, formData, { headers }).then(result => result.data);
    }
}
