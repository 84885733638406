import banners from "./banners";
import boards from "./boards";
import center from "./center";
import companies from "./companies";
import levels from "./levels";
import portfolios from "./portfolios";
import setting from "./setting";
import shop from "./shop";
import statistics from "./statistics";
import terms from "./terms";
import users from "./users";

import closer from "./closer";
import giftishow from "./giftishow";
import issue from "./issue";
import calculations from "./calculations";
import ksnet from "./ksnet";
import * as common from "./common";
import * as ip from "./ip"

export default {
    banners,
    boards,
    center,
    companies,
    levels,
    portfolios,
    setting,
    shop,
    statistics,
    terms,
    users,

    closer,
    giftishow,
    issue,
    calculations,
    ksnet,
    ...ip,
    ...common,
};
