import banners from "./banners";
import boards from "./boards";
import center from "./center";
import companies from "./companies";
import me from "./me";
import partners from "./partners";
import portfolios from "./portfolios";
import shop from "./shop";
import technicians from "./technicians";
import terms from "./terms";
import users from "./users";


export default {
    banners,
    boards,
    center,
    companies,
    me,
    partners,
    portfolios,
    shop,
    technicians,
    terms,
    users,
};
