import axios from "@/plugins/axios";

let url = "/api/v1/companies";

export default {
    getCompanies(data){
        return axios.get(url, data).then(result => result.data);
    },
    getCompany(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
}
