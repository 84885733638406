import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../plugins/axios";
import api from "../api";

Vue.use(Vuex)

var refreshToken = sessionStorage.getItem("refreshToken");
var accessToken = sessionStorage.getItem("accessToken");

var payload = JSON.parse(atob(accessToken?.split('.')[1] || '') || "{}");
var scope = payload?.scope || [];

export default new Vuex.Store({
    state: {
        refreshToken,
        accessToken,
        payload,
        scope,
    },
    mutations: {
        login(state, { refreshToken, accessToken  }) {
            state.refreshToken = refreshToken;
            state.accessToken = accessToken;

            state.payload = JSON.parse(atob(accessToken?.split('.')[1] || '') || "{}");
            state.scope = state.payload?.scope || [];

            axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

            sessionStorage.setItem("refreshToken", refreshToken);
            sessionStorage.setItem('accessToken', accessToken);
        },

        logout(state) {
            state.refreshToken = undefined;
            state.accessToken = undefined;

            state.payload = {};
            state.scope = [];

            axios.defaults.headers.common['Authorization'] = '';

            sessionStorage.removeItem("refreshToken");
            sessionStorage.removeItem("accessToken");
        },
    },
    actions: {
        async login({ commit }, { username, password, _certification }) {
            var { refreshToken, lastAccessLog } = await api.auth.getRefreshToken({ username, password, _certification });
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            if(lastAccessLog) alert(`직전로그인 성공일시: ${lastAccessLog.createDate.toDateTime()}\nip: ${lastAccessLog.ip}`)

            commit("login", { refreshToken, accessToken });
        },

        logout({ commit }) {
            commit("logout");
        },

        async refresh({ commit }, refreshToken){
            var { accessToken } = await api.auth.getAccessToken(refreshToken);

            commit("login", { refreshToken, accessToken });
        },
    },
    modules: {}
});
