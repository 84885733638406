import axios from "@/plugins/axios";

let url = "/api/v1/shop/categories";

export default {
    getCategories(data){
        return axios.get(url, data).then(result => result.data);
    },
    getCategory(data){
        return axios.get(`${url}/${data.code}`).then(result => result.data);
    }
}
