import axios from "@/plugins/axios";

let url = "/api/console/closers";

export default {
    getClosers(data){
        return axios.get(url, data).then(result => result.data);
    },
    getCloser(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postCloser(data){
        return axios.post(url, data).then(result => result.data);
    },
    putCloser(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteCloser(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
