import axios from "@/plugins/axios";

let url = "/api/console/shop/exhibitions";

export default {
    getExhibitions(data){
        return axios.get(url, data).then(result => result.data);
    },
    postExhibition(data){
        return axios.post(url, data).then(result => result.data);
    },
    putExhibition(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteExhibition(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
