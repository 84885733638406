import axios from "@/plugins/axios";

export default {
    getOptions(data){
        return axios.get(`/api/console/shop/products/${data._product}/options`, data).then(result => result.data);
    },
    postOption(data){
        return axios.post(`/api/console/shop/products/${data._product}/options`, data).then(result => result.data);
    },
    putOption(data){
        return axios.put(`/api/console/shop/products/${data._product}/options/${data._id}`, data).then(result => result.data);
    },
    deleteOption(data){
        return axios.delete(`/api/console/shop/products/${data._product}/options/${data._id}`).then(result => result.data);
    }
}
