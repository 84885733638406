import axios from "@/plugins/axios";

import autocomplete from "./autocomplete";
import options from "./options";
import supplies from "./supplies";

let url = "/api/console/shop/products";

export default {
    autocomplete,
    options,
    supplies,

    getProducts(data){
        return axios.get(url, data).then(result => result.data);
    },
    getProduct(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postProduct(data){
        return axios.post(url, data).then(result => result.data);
    },
    putProduct(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteProduct(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },

    postThumb(_product, file){
        var data = new FormData(); data.append('thumb', file);
        var headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`${url}/${_product}/thumb`, data, { headers });
    },

    deleteThumb(_product){
        return axios.delete(`${url}/${_product}/thumb`);
    },

    postImage(_product, index, file){
        var data = new FormData(); data.append('image', file);
        var headers = { 'Content-Type': 'multipart/form-data' };
        return axios.post(`/api/console/shop/products/${_product}/images/${index}`, data, { headers });
    },

    deleteImage(_product, index){
        return axios.delete(`/api/console/shop/products/${_product}/images/${index}`);
    },
}
