import axios from "@/plugins/axios";

let url = "/api/v1/boards";

export default {
    getComments(data){
        return axios.get(`${url}/${data._board}/comments`, data).then(result => result.data);
    },
    getComment(data){
        return axios.get(`${url}/${data._board}/comments/${data._id}`).then(result => result.data);
    },
    postComment(data){
        return axios.post(`${url}/${data._board}/comments`, data).then(result => result.data);
    },
    putComment(data){
        return axios.put(`${url}/${data._board}/comments/${data._id}`, data).then(result => result.data);
    },
    deleteComment(data){
        return axios.delete(`${url}/${data._board}/comments/${data._id}`).then(result => result.data);
    }
}
