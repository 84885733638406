import axios from "@/plugins/axios";

let url = "/api/console/banners";

export default {
    getBanners(data){
        return axios.get(url, data).then(result => result.data);
    },
    getBanner(data){
        return axios.get(`${url}/${data._id}`, data).then(result => result.data);
    },
    postBanner(data){
        return axios.post(url, data).then(result => result.data);
    },
    putBanner(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteBanner(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postImage(_banner, index, file){
        var formData = new FormData(); formData.append("image", file);
        var headers = { "Content-Type": "multipart/formdata" };
        return axios.post(`${url}/${_banner}/images/${index}`, formData, { headers }).then(result => result.data);
    },
    deleteImage(_banner, index){
        return axios.delete(`${url}/${_banner}/images/${index}`).then(result => result.data);
    }
}
