import axios from "@/plugins/axios";

let url = "/api/v1/me/inquires";

export default {
    getInquires(data){
        return axios.get(url, data).then(result => result.data);
    },
    postInquire(data){
        return axios.post(url, data).then(result => result.data);
    },
    deleteInquire(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    },
    postImage(data, image, index){
        var formData = new FormData(); formData.append("image", image);
        var headers = { "Content-Type": "multipart/formdata" }
        return axios.post(`${url}/${data._id}/images/${index}`, formData, { headers }).then(result => result.data);
    }
}
