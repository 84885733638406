import brands from "./brands";
import categories from "./categories";
import coupons from "./coupons";
import deliveries from "./deliveries";
import dashboard from "./dashboard";
import exhibition from "./exhibition";
import inquires from "./inquires";
import islands from "./islands";
import orders from "./orders";
import products from "./products";
import purchases from "./purchases";
import reviews from "./reviews";
import shippings from "./shippings";
import statistics from "./statistics";

export default {
    brands,
    categories,
    coupons,
    dashboard,
    deliveries,
    exhibition,
    inquires,
    islands,
    orders,
    products,
    purchases,
    reviews,
    shippings,
    statistics,
}
