import axios from "@/plugins/axios";
import bookmark from "./bookmark";
import categories from "./categories";
import spaces from "./spaces";

let url = "/api/v1/portfolios";

export default {
    bookmark,
    categories,
    spaces,
    
    getPortfolios(data){
        return axios.get(url, data).then(result => result.data);
    },
    getPortfolio(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    putPortfolio(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    }
}
