import axios from "@/plugins/axios";

export default {
    getRefreshToken({ username, password, _certification }){
        var basicToken = "Basic " + new Buffer(`${username}:${password}`).toString('base64');

        return axios.get(`/api/auth/refreshToken`, {
            headers: { "Authorization": basicToken },
            params: { _certification }
        }).then(result => result.data);
    },
    getAccessToken(refreshToken){
        var bearerToken = `Bearer ${refreshToken}`;

        return axios.get(`/api/auth/accessToken`, {
            headers: { "Authorization": bearerToken }
        }).then(result => result.data);
    }
}
