import axios from "@/plugins/axios";

let url = "/api/console/terms";

export default {
    getTerms(data){
        return axios.get(url, data).then(result => result.data);
    },
    getTerm(data){
        return axios.get(`${url}/${data._id}`).then(result => result.data);
    },
    postTerm(data){
        return axios.post(url, data).then(result => result.data);
    },
    putTerm(data){
        return axios.put(`${url}/${data._id}`, data).then(result => result.data);
    },
    deleteTerm(data){
        return axios.delete(`${url}/${data._id}`).then(result => result.data);
    }
}
